import { PropsWithChildren } from "react";
import { ThemeContext } from '../../scss/theme-context';
import React from "react";

export default function Theme({children}: {children: any}) {

     
      
      const [theme, setTheme] = React.useState('dark');

      React.useEffect(() => {
            document.documentElement.setAttribute('data-bs-theme', theme)
      }, [theme])

      return <ThemeContext.Provider value={{ theme, setTheme }}>
      <div className={`theme-${theme}`}>
            <>{children}</>
      </div>
    </ThemeContext.Provider>
}