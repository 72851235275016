import { FC, PropsWithChildren } from 'react';
import { Permission } from 'auto/types/permission';

type PageUnprocessed = [FC] | [FC, Permission | Permission[]];
export type PageProcessed = { layout: FC<PropsWithChildren<{}>>; content: FC; permission?: Permission | Permission[] };

type PageContent = PageUnprocessed | PageProcessed;

export type Pages = Record<string, PageContent>;

function processContent(content: PageUnprocessed, layout: FC): PageProcessed {
      return { content: content[0], layout, permission: content[1] };
}

export const createPages = <T extends Pages>(layout: FC, routes: T): T => {
      const temp = {} as any;

      for (let key in routes) temp[key] = processContent(routes[key] as any, layout);

      return temp as T;
};

export const mergePages = <A extends Pages, B extends Pages, C extends Pages, D extends Pages, E extends Pages, F extends Pages>(
      routesA: A,
      routesB: B,
      routesC: C,
      routesD: D,
      routesE: E,
      routesF: F
): A & B & C & D & E => {
      return { ...routesA, ...routesB, ...routesC, ...routesD, ...routesE, ...routesF };
};
