import { useGlobalObservable } from 'open-observable';

import React, { FC } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { authAccessToken } from '../state/auth-state-key';
import { getAccessToken } from 'layout/viewsTenant/autenticacao';

export const AuthState: FC = () => {
      const location = useLocation();
      const navigate = useNavigate();

      const urlParams = new URLSearchParams(window.location.search);
      const urlCode = urlParams.get('code');

      const inAuthPage = location.pathname.startsWith('/login');

      const token = useGlobalObservable(authAccessToken);

      const localStorageToken = localStorage.getItem('access_token');

      const getApiToken = async () => {
            if (urlCode) {
                  localStorage.setItem('code', urlCode);
                  localStorage.setItem('access_token', 'a');
                  try {
                        const x = await getAccessToken(urlCode);
                        localStorage.setItem('access_token', x.access_token);
                        token.next({
                              access_token: x.access_token,
                              authorized: true,
                              permission: 'Administrador', 
                        });
                  } catch (error) {
                        console.error('Error fetching access token:', error);
                  }
            }
      };

      getApiToken();

      React.useEffect(() => {
            if (localStorageToken && inAuthPage) {
                  token.next({ access_token: localStorageToken, authorized: true, permission: 'Administrador' });
                  return navigate('/erp');
            }

            if (!localStorageToken) {
                  return navigate('/login');
            }
      }, [inAuthPage, localStorageToken]);

      return null;
};
