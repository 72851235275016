
import './assets/css/main.css';


import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { GlobalObservable } from "open-observable";
import { Config } from './other/config';


import { createRoot } from 'react-dom/client';
import { App } from 'App';
import { AuthState } from 'layout/auth/auth-state/components';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
      <GlobalObservable>
            <BrowserRouter>
                  <App />
                  <Config />
                  <AuthState />
            </BrowserRouter>
      </GlobalObservable>
);

reportWebVitals();
