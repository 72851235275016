import LoadingPage from 'components/LoadingPage';
import React from 'react';

interface URLParameters {
      [key: string]: string;
}

async function getLoginUrl() {
      const result = await fetch(process.env.REACT_APP_NEW_LOGIN_URL as string, {
            credentials: 'include',
            method: 'GET',
            headers: {
                  'Content-Type': 'application/json',
            },
      });

      const data = await result.text();
      return data;
}

const getTokenUrl =
      'https://qualidadeemsaudeorg.b2clogin.com/4c1b4978-a902-4648-88c4-4cb1c72fd0dd/oauth2/v2.0/token?p=b2c_1_cadastro_usuario';

export async function getAccessToken(token: string) {
      var details = {
            grant_type: 'authorization_code',
            client_id: '12d00f33-7f56-4440-96c1-2ae383fe85bb',
            scope: 'openid offline_access 12d00f33-7f56-4440-96c1-2ae383fe85bb profile',
            client_secret: 'Zf18Q~KWNpCO8lOqrb.JVbezypwtsYCrDU6bVb94',
            code: token,
      };

      var formBody: any = [];
      for (var property in details) {
            var encodedKey = encodeURIComponent(property);
            var encodedValue = encodeURIComponent(details[property as keyof typeof details]);
            formBody.push(encodedKey + '=' + encodedValue);
      }
      formBody = formBody.join('&');

      const result = await fetch(getTokenUrl as string, {
            credentials: 'include',
            method: 'POST',
            headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                  Connection: 'keep-alive',
                  Accept: '*/*',
                  'Accept-Encoding': 'gzip, deflate, br',
                  Host: 'qualidadeemsaudeorg.b2clogin.com',
            },
            body: formBody,
      });

      if (!(result.status >= 200 && result.status <= 299)) {
            throw new Error(`Failed to get token: ${result.status} ${result.statusText}`);
      }

      const data = await result.json();
      return data;
}

function LoginView() {
      const localStorageToken = localStorage.getItem('access_token');

      async function redirectToLogin() {
            const x = await getLoginUrl();
            return window.open(substituirRedirectURI(x, encodeURI(novoRedirectURI)), '_self');
      }

      React.useEffect(() => {
            if (!localStorageToken) redirectToLogin();
      }, [localStorageToken]);

      function substituirRedirectURI(url: string, novoValor: string): string {
            const urlObj = new URL(url);
            const parametros: URLParameters = {};

            urlObj.searchParams.forEach((valor, chave) => {
                  parametros[chave] = valor;
            });

            if (parametros['redirect_uri']) {
                  parametros['redirect_uri'] = novoValor;
            }

            urlObj.search = new URLSearchParams(parametros).toString();

            return urlObj.toString();
      }

      const novoRedirectURI: string = process.env.REACT_APP_AZURE_REDIRECT_URI ?? ''

      return <LoadingPage />;
}

export default LoginView;
