import { GlobalObservableKey, useGlobalObservable } from "open-observable";
import React, { ReactNode } from "react";

type Props = {
      modal: any;
}

export const modalsArray = new GlobalObservableKey<any>('modals', []);

export default function useModal() {
      const modalValue = useGlobalObservable(modalsArray);

      React.useEffect(() => {
            return () => { };
      }, []);


      return React.useCallback(
            (element: ReactNode) => {
                  if (!modalValue) return;
                  modalValue.next((old: any) => {
                        return [...old, { asd: element }];
                  });
            },
            [modalValue]
      );

}