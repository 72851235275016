export default function LoadingPage() {
      return (
            <div className='min-vh-100 w-100 d-flex flex-column align-items-center justify-content-center '>
                  <img
                        height={150}
                        className='qs-loading-logo'
                        src={require('../../assets/svg/logo_qualidade-em-saude.svg').default}
                        alt='qs-loading-logo'
                  />
                  <div className='spinner-border  border-5 mt-6 p-5' role='status'></div>
            </div>
      );
}
